import { useTranslation } from 'features/localization/provider';
import React, { useEffect } from 'react';

type PageProps = {
    title?: string;
    component: React.ElementType;
};

const Page: React.FC<PageProps> = (props) => {
    const translate = useTranslation();

    useEffect(() => {
        if (props.title) {
            document.title = props.title;
        } else {
            document.title = translate.browser_title_login_default;
        }
    }, [props]);

    const PageComponent = props.component;

    return <PageComponent />;
};

export default Page;
