import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import LocalizedStrings from 'react-localization';
import { useAppDispatch, useAppSelector } from '../../shared/lib/redux-hooks';
import en from './en_US.json';
import { DictionarySelector } from './slice';
import { useGetLanguageLocalizationQuery } from 'shared/api/localization';

export const localization = new LocalizedStrings({
    en: en,
});

interface LocalizationContextType {
    language: string;
    translate: typeof localization;
    setTranslate: import('react').Dispatch<import('react').SetStateAction<typeof localization>>;
    loadingLocale: boolean;
    setLoadingLocale: import('react').Dispatch<import('react').SetStateAction<boolean>>;
}

export const LocalizationContext = createContext<LocalizationContextType>({
    language: 'en',
    translate: localization,
    setTranslate: (translate) => {},
    loadingLocale: false,
    setLoadingLocale: (loading) => {},
});

export const useLanguage = () => {
    return useContext(LocalizationContext);
};

export const useTranslation = (): typeof localization => {
    const { language } = useLanguage();
    const currentDictionary = useAppSelector(DictionarySelector);
    return currentDictionary?.[language] ? currentDictionary[language] : localization;
};

export const LocalizationProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    const language = localStorage.getItem('displayLanguage') || 'en';
    const [translate, setTranslate] = useState<typeof localization>(localization);
    const [loadingLocale, setLoadingLocale] = useState(false);

    const { data: languagesLocalization, isSuccess } = useGetLanguageLocalizationQuery(language);
    const dispatch = useAppDispatch();
    const value = useMemo(
        () => ({
            language,
            translate,
            setTranslate,
            loadingLocale,
            setLoadingLocale,
        }),
        [loadingLocale, localization]
    );

    useEffect(() => {
        if (languagesLocalization && isSuccess) {
            localization.setContent({ [language]: languagesLocalization });
            localization.setLanguage(language);
        }
    }, [dispatch, languagesLocalization, isSuccess]);

    return <LocalizationContext.Provider value={value}>{children}</LocalizationContext.Provider>;
};
