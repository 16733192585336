import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { directionSlice } from 'pages/login/direction-slice';
import { provisionUserSlice } from 'pages/login/provision-slice';
import { loginAuthSlice } from 'pages/login/slice';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { localizationSlice } from '../../features/localization/slice';
import { emptySplitApi } from '../../shared/api/empty-split-api';

const sliceList = [localizationSlice, loginAuthSlice, provisionUserSlice, directionSlice];

const api = emptySplitApi;

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [api.reducerPath, 'provision', 'direction'],
};

export const store = configureStore({
    reducer: persistReducer(
        persistConfig,
        combineReducers({
            ...sliceList.reduce((acc, slice) => ({ ...acc, [slice.name]: slice.reducer }), {}),
            [api.reducerPath]: api.reducer,
        })
    ),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: {},
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: { warnAfter: 128 },
            serializableCheck: {
                warnAfter: 128,
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(api.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type LoadingState = 'idle' | 'pending' | 'succeeded' | 'failed';

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const persistor = persistStore(store);
