export interface IParsedJwtToken {
    sub: string;
    bto: any;
    sid: string;
    eid: string;
    usr: string; // email
    utp: string[];
    rol: string[];
    grp: string[];
    org: string[];
    jti: string;
    iss: string;
    iat: number;
    exp: number;
}

export const parseJwtToken = (token: string): IParsedJwtToken => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};
