import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app';
import { PortalTypes } from './lib/useGetPlatforms';

export interface IInitialState {
    destination: PortalTypes | null;
    refSource: string | null;
}

export const initialState: IInitialState = {
    destination: null,
    refSource: null,
};

export const directionSlice = createSlice({
    name: 'direction',
    initialState,
    reducers: {
        setDestination: (state, action: PayloadAction<IInitialState>) => {
            Object.assign(state, action.payload);
        },
    },
});

const { reducer, actions } = directionSlice;
export default reducer;

export const { setDestination } = actions;

export const destinationSelector = (state: RootState) => state.direction.destination as PortalTypes | null;
export const refSourceSelector = (state: RootState) => state.direction.refSource as string | null;
