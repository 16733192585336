import React, { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, RouteProps, Switch, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/lib/redux-hooks';
import { routes } from 'shared/lib/routes';
import { useTranslation } from '../features/localization/provider';
import { CircularLoader } from './login/components/circularLoader';
import { destinationSelector, setDestination } from './login/direction-slice';
import { PortalTypes } from './login/lib/useGetPlatforms';
import { authSelector, platformsAvailableSelector } from './login/slice';
import Page from './page';

const { login, home } = routes;
const HomePage = lazy(() => import('../shared/components/router/home-router'));
const ChangePasswordPage = lazy(() => import('./login/pages/changePassword'));
const LoginPage = lazy(() => import('./login/pages/login'));
const LoginSSO = lazy(() => import('./login/pages/sso'));
const ExpiredLinkRouter = lazy(() => import('../shared/components/router/expired-link-router'));
const NewPasswordRouter = lazy(() => import('../shared/components/router/reset-password-route'));

interface PrivateRouteProps extends RouteProps {
    render: (props: any) => React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ render: RenderComponent, ...rest }) => {
    const authentication = useAppSelector(authSelector);
    const platformsAvailable = useAppSelector(platformsAvailableSelector);

    return (
        <Route
            {...rest}
            render={(props) => {
                return authentication.jwt !== '' || (props.location.pathname === home.index() && platformsAvailable) ? (
                    RenderComponent(props)
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location },
                        }}
                    />
                );
            }}
        />
    );
};

export const MainRouter = () => {
    const translate = useTranslation();
    const location = useLocation<{ from: string }>();
    const destination = useAppSelector(destinationSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const referrerFromQuery = queryParams.get('destination');
        const refSourceQuery = queryParams.get('refSource');

        dispatch(
            setDestination({
                destination: referrerFromQuery
                    ? (decodeURIComponent(referrerFromQuery) as PortalTypes)
                    : destination
                    ? destination
                    : null,
                refSource: refSourceQuery ? refSourceQuery : null,
            })
        );
    }, [location, destination]);

    return (
        <Suspense fallback={<CircularLoader title={translate.loading_label} />}>
            <Switch>
                <Route exact path={login.index()} render={(props) => <Page {...props} component={LoginPage} />} />
                <Route
                    path={login.sso()}
                    render={(props) => <Page {...props} component={LoginSSO} title={translate.browser_title_sso} />}
                />
                <Route
                    path={login.forgotPassword()}
                    render={(props) => (
                        <Page
                            {...props}
                            component={ChangePasswordPage}
                            title={translate.browser_title_forgot_pass_page}
                        />
                    )}
                />
                <Route
                    exact
                    path={login.expiredLink()}
                    render={(props) => (
                        <Page {...props} component={ExpiredLinkRouter} title={translate.browser_title_expired_link} />
                    )}
                />
                <Route
                    path={login.resetPassword()}
                    render={(props) => (
                        <Page {...props} component={NewPasswordRouter} title={translate.browser_title_password_reset} />
                    )}
                />
                <PrivateRoute
                    exact
                    path={home.index()}
                    render={(props) => (
                        <Page {...props} component={HomePage} title={translate.browser_title_home_page} />
                    )}
                />
                <Redirect from="*" to="/" />
            </Switch>
        </Suspense>
    );
};
