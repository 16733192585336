import { FC, ReactNode, createContext, useContext, useState } from 'react';

export interface INotification {
    stateVisible: boolean;
    title: string;
    severity: Severity;
    autoHide?: boolean;
}

// Define the type for your localization context state
interface NotificationContextType {
    notification: INotification;
    initialState: INotification;
    // setNotification: (info: any) => void;
    setNotification: React.Dispatch<React.SetStateAction<INotification>>;
}

type Severity = 'success' | 'info' | 'warning' | 'error';

export const initialStateNotification: INotification = {
    stateVisible: false,
    title: '',
    severity: 'success',
};

// Create a new context and provide an initial value
const NotificationsContext = createContext<NotificationContextType>({
    notification: initialStateNotification,
    initialState: initialStateNotification,
    setNotification: () => {},
});

export const NotificationProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    const initialState = initialStateNotification;
    const [notification, setNotification] = useState<INotification>(initialState);

    const closeSnackbarAfterDelay = (delay: number) => {
        setTimeout(() => {
            setNotification((prevNotification) => ({
                ...prevNotification,
                stateVisible: false,
            }));
        }, delay);
    };

    const contextValue: NotificationContextType = {
        notification,
        initialState,
        setNotification: (newNotification) => {
            const values = newNotification as INotification;
            // If new notification is provided, set it and auto close after 5 seconds
            setNotification(values);
            if (values.autoHide) {
                closeSnackbarAfterDelay(3000); // 3000 milliseconds (5 seconds)
            }
        },
    };

    return <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>;
};

// Custom hook to use the localization context
export const useNotification = () => {
    const context = useContext(NotificationsContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};
