import { environment } from 'shared/lib/environment';
import { ALLOWED_ROLES_ASSESSMENT, ALLOWED_ROLES_CAC, ALLOWED_ROLES_LANGUAGE_ACADEMY } from '../../../constants';

export type PortalTypes =
    | 'Language Academy'
    | 'Assessment Portal'
    | 'Client Admin Center'
    | 'Trainer Portal'
    | 'Admin Center';

export interface IDefaultPlatform {
    portal: PortalTypes;
    redirectPath: string;
}

export interface IRequiredPlatforms extends IDefaultPlatform {
    role: string[];
}

export const helperPlatformList = ({
    domain = environment.API_HOST,
    refSource = null,
}: {
    domain: string;
    refSource: string | null;
}) => {
    const PLATFORMS_WITH_REQUIRED_ROLES: IRequiredPlatforms[] = [
        {
            portal: 'Language Academy',
            role: ALLOWED_ROLES_LANGUAGE_ACADEMY,
            redirectPath: `${domain}/${refSource ?? `app`}`,
        },
        {
            portal: 'Assessment Portal',
            role: ALLOWED_ROLES_ASSESSMENT,
            redirectPath: `${domain}/${refSource ?? `assessment`}`,
        },
        { portal: 'Client Admin Center', role: ALLOWED_ROLES_CAC, redirectPath: `${domain}/${refSource ?? `client`}` },
        // Remove the internal portals, since they tend to use sso for their login
        // { portal: 'Trainer Portal', role: ALLOWED_ROLES_TRAINER, redirectPath: `${domain}/trainer` },
        // { portal: 'Admin Center', role: ALLOWED_ROLES_ADC, redirectPath: `${domain}/admin` },
    ];

    return PLATFORMS_WITH_REQUIRED_ROLES;
};
