import { ReactNode } from 'react';
import { LocalizationProvider } from '../../features/localization/provider';
import 'moment/locale/ca';
import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/id';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/sk';
import 'moment/locale/tr';
import 'moment/locale/vi';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';

export const withLocalization = (component: () => ReactNode) => () => {
    return <LocalizationProvider>{component()}</LocalizationProvider>;
};
