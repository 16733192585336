import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app';
import { isLocalhost } from 'app/hocs/withRouter';
import { INotification } from 'features/notifications/provider';
import { environment } from 'shared/lib/environment';
import { IParsedJwtToken, parseJwtToken } from 'utils/parseJWToken';
import { IDefaultPlatform, helperPlatformList } from './lib/useGetPlatforms';

export interface SimpleAutheReqPayload {
    username: string;
    password: string;
}

export interface CredentialResPayload {
    expiryInMinutes: number;
    jwt: string;
    mustChangePassword: boolean;
    userId: string;
}

export interface AuthResPayload extends CredentialResPayload {
    legacyToken: string;
}

export interface ILegacyToken {
    legacyToken: string;
}

export interface SSOAuthReqPayload {
    client: string;
    secret: string;
}

export interface IUserInformation {
    active: boolean;
    avatar: string;
    emailaddress: string;
    firstname: string;
    jobTitle: string;
    languageId: string;
    lastname: string;
    lockout: boolean;
    middleName: string;
    screenname: string;
    ssoId: string;
    timezoneId: string;
    userId: number;
    uuid: string;
}

export interface IdentityProviderResPayload {
    idpUrl: string;
    authnRequest: {
        id: string;
        issueInstant: string;
        authnRequestXml: string;
        encodedAuthnRequest: string;
    };
    signatureAlgorithm: string;
    relayState: string;
    signature: string;
}

export const identityProvider: IdentityProviderResPayload = {
    idpUrl: '',
    authnRequest: {
        id: '',
        issueInstant: '',
        authnRequestXml: '',
        encodedAuthnRequest: '',
    },
    signatureAlgorithm: '',
    relayState: '',
    signature: '',
};

const credential: AuthResPayload = {
    expiryInMinutes: 0,
    jwt: '',
    mustChangePassword: false,
    userId: '',
    legacyToken: '',
};

interface IParsedJWT {
    [key: string]: any;
}

export interface IInitialState {
    credential: AuthResPayload;
    parsedJwt: IParsedJWT;
    platforms: IDefaultPlatform[] | null;
    identityProvider: IdentityProviderResPayload;
    notificationMessage: null | INotification;
    disabled: boolean;
}

export const initialState: IInitialState = {
    credential: credential,
    parsedJwt: {},
    platforms: null,
    identityProvider: identityProvider,
    notificationMessage: null,
    disabled: false,
};

export interface ISetCredential extends AuthResPayload {
    refSource: string | null;
}

export const loginAuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearCredentials: (state) => {
            return {
                ...state,
                credential: credential,
                parsedJwt: {},
                platforms: null,
            };
        },
        setCredential: (state, action: PayloadAction<ISetCredential>) => {
            const { refSource, ...newPayload } = action.payload;
            const parsedJwtToken = parseJwtToken(newPayload.jwt);
            const domainUrl = isLocalhost ? environment.API_HOST : window.location.origin;
            const platformsList = helperPlatformList({ domain: domainUrl, refSource: action.payload.refSource });
            const platforms = platformsList
                .filter((platform) => {
                    return platform.role.some((role) => {
                        return parsedJwtToken.rol.includes(role);
                    });
                })
                .map((platform) => ({ portal: platform.portal, redirectPath: platform.redirectPath }));
            localStorage.setItem('credential', JSON.stringify(newPayload));
            localStorage.setItem('platforms', JSON.stringify(platforms));
            state.platforms = platforms;
            state.parsedJwt = parsedJwtToken;
            state.credential = newPayload;
        },
        logout: () => {
            localStorage.clear();
            return initialState;
        },
        updateNotification: (state, action: PayloadAction<INotification | null>) => {
            return {
                ...state,
                notificationMessage: action.payload,
            };
        },
        setPlatforms: (state, action: PayloadAction<IDefaultPlatform[] | null>) => {
            return {
                ...state,
                platforms: action.payload,
            };
        },
    },
    extraReducers: () => {},
});

const { reducer, actions } = loginAuthSlice;
export default reducer;

export const { logout, updateNotification, setPlatforms, clearCredentials, setCredential } = actions;

export const authSelector = (state: RootState) => state.auth.credential as AuthResPayload;
export const disabledLoginSelector = (state: RootState) => state.auth.disabled as boolean;
export const platformsAvailableSelector = (state: RootState) => state.auth.platforms as IDefaultPlatform[];
export const notificationMessageSelector = (state: RootState) => state.auth.notificationMessage as INotification | null;
export const parseJWTSelector = (state: RootState) => state.auth.parsedJwt as IParsedJwtToken;
