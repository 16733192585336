import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNotification } from 'features/notifications/provider';
import { useCallback, useMemo } from 'react';

export default function CustomizedSnackbars() {
    const { notification, setNotification } = useNotification();
    const { title, severity } = notification;

    const alertProps = useMemo(() => {
        const defaultProps = {
            severity: severity,
        };
        switch (severity) {
            case 'success':
                return {
                    ...defaultProps,
                };
            case 'info':
                return {
                    ...defaultProps,
                };
            case 'warning':
                return {
                    ...defaultProps,
                    icon: <ErrorOutlineRoundedIcon />,
                };
            default:
                return {
                    ...defaultProps,
                    icon: <ErrorOutlineRoundedIcon />,
                };
        }
    }, [severity]);

    const handleClose = useCallback(
        (event?: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            setNotification((prevNotification) => ({
                ...prevNotification,
                stateVisible: false,
            }));
        },
        [setNotification]
    );

    return (
        <Snackbar
            open={notification.stateVisible}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} variant="filled" sx={{ width: '100%' }} {...alertProps}>
                {title}
            </Alert>
        </Snackbar>
    );
}
