import { CircularProgress, Grid, Typography } from '@mui/material';

export const CircularLoader: React.FC<{ title?: string }> = ({ title = 'Signing in...' }) => {
    return (
        <Grid container gap={3}>
            <Grid
                item
                xs={12}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '8px',
                }}
            >
                <CircularProgress color="success" />
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Typography className="medium-regular">{title}</Typography>
            </Grid>
        </Grid>
    );
};
