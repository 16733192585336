import { emptySplitApi } from './empty-split-api';
import { routes } from '../lib/routes';

export interface LanguagesList {
    id: string;
    endonym: string;
    languageIso2?: string;
    name: string;
    attributes?: {
        LanguageCode: string;
        VCR: boolean;
    };
    translation: { [key: string]: string };
}

export interface LanguageLocalisation {
    key: string;
    text: string;
}

export interface PreferredLanguagesList {
    code: string;
    name: string;
    endonym: string;
}

export const localizationApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getLanguageLocalization: builder.query<any, string>({
            query: routes.api.localization.langauge,
            transformResponse(result: LanguageLocalisation[]) {
                const resultObject: { [key: string]: string } = {};
                result.forEach((item) => {
                    resultObject[item.key] = item.text;
                });
                return resultObject;
            },
        }),
    }),
});

export const { useGetLanguageLocalizationQuery } = localizationApi;
