import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LanguagesList } from '../../shared/api/localization';
import { RootState } from '../../app';

interface Dictionary {
    [key: string]: string;
}

interface LocalizationState {
    dictionary: { [key: string]: string } | null;
    languages: LanguagesList[] | null;
    loading: boolean;
}

const initialState: LocalizationState = {
    dictionary: null,
    languages: null,
    loading: false,
};

export const localizationSlice = createSlice({
    name: 'localize',
    initialState,
    reducers: {
        setDictionary: (state, action: PayloadAction<Dictionary | null>) => {
            return {
                ...state,
                dictionary: action.payload,
            };
        },
        setLanguages: (state, action: PayloadAction<LanguagesList[]>) => {
            return {
                ...state,
                languages: action.payload,
            };
        },
    },
});

const { reducer, actions } = localizationSlice;

export default reducer;

export const { setDictionary, setLanguages } = actions;

export const DictionarySelector = (state: RootState) => state.localize.dictionary;
export const LanguagesSelector = (state: RootState) => state.localize.languages;
