import '@arcadia-projects/portal2-ui-library/dist/index.esm.css';
import { Box, Container } from '@mui/material';
import { MainRouter } from '../pages';
import { withHocs } from './hocs';
import './index.css';

function App() {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '#f2f4f4',
            }}
        >
            <Container
                sx={(theme) => ({
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    height: '100vh',
                    overflow: 'auto',
                    [theme.breakpoints.down('md')]: {
                        backgroundColor: '#fff',
                        alignItems: 'start',
                        paddingTop: '20px',
                    },
                })}
            >
                <MainRouter />
            </Container>
        </Box>
    );
}

export default withHocs(App);
export * from './model';
