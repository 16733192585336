import { Provider } from 'react-redux';
import { persistor, store } from '../model';
import { ReactNode } from 'react';
import { PersistGate } from 'redux-persist/integration/react';

export const withStore = (component: () => ReactNode) => () =>
    (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {component()}
            </PersistGate>
        </Provider>
    );
