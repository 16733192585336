import { ReactNode } from 'react';
import { NotificationProvider } from '../../features/notifications/provider';
import CustomizedSnackbars from 'pages/login/components/customSnackbar';

export const withNotifications = (component: () => ReactNode) => () =>
    (
        <NotificationProvider>
            <CustomizedSnackbars />
            {component()}
        </NotificationProvider>
    );
