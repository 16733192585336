import { createApi } from '@reduxjs/toolkit/query/react';
import { defaultApiFetcher } from '../lib/environment';

export const headersAppJson = { 'Content-type': 'application/json' };
export const headersAppJsonCharUtf8 = {
    'Content-type': 'application/json; charset=UTF-8',
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
    baseQuery: defaultApiFetcher,
    reducerPath: 'api',
    endpoints: () => ({}),
    tagTypes: ['User'],
});
