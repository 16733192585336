import { ReactNode } from 'react';
import { Router } from 'react-router-dom';
import { environment } from '../../shared/lib/environment';
import { createBrowserHistory } from 'history';

export const isLocalhost = window.location.hostname === 'localhost';
const basename = isLocalhost ? '/' : environment.DEPLOY_PATH;

const history = createBrowserHistory({ basename });

export const withRouter = (component: () => ReactNode) => () => <Router history={history}>{component()}</Router>;
