import { createTheme } from '@mui/material';

const commonStyles = {
    color: '#233142',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    lineHeight: 'normal',
};

const headingStyles = {
    fontSize: '18px',
    fontWeight: 400,
};

const mediumStyles = {
    fontSize: '14px',
    fontWeight: 400,
};

const smallStyles = {
    fontSize: '12px',
    fontWeight: 400,
};

const extraSmallStyles = {
    fontSize: '10px',
    fontWeight: 400,
};

export const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.className?.includes('primary') && {
                        minHeight: '48px',
                        borderRadius: '4px',
                        color: '#0A4259',
                        textAlign: 'center',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: '20px' /* 142.857% */,
                        textTransform: 'none',
                        background: '#DDB815',
                        '&:hover': {
                            background: '#F2D452 !important',
                        },
                        '&:active': {
                            backgroundColor: '#D7AF00',
                        },
                        '&:disabled': {
                            background: '#F1E3A1',
                            opacity: 0.3,
                        },
                    }),
                    ...(ownerState.className?.includes('secondary') && {
                        minHeight: '48px',
                        borderRadius: '4px',
                        border: '1px solid #0A4259',
                        color: '#0A4259',
                        textAlign: 'center',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px' /* 142.857% */,
                        background: 'none',
                        textTransform: 'none',
                        '&:hover': {
                            border: '1px solid #0A4259',
                            background: '#F2F4F4 !important',
                        },
                        '&:active': {
                            backgroundColor: '#D1D1D1',
                        },
                    }),
                    ...(ownerState.className?.includes('ghost') && {
                        minHeight: '48px',
                        borderRadius: '4px',
                        color: '#0A4259',
                        textAlign: 'center',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px' /* 142.857% */,
                        background: 'none',
                        textTransform: 'none',
                        '&:hover': {
                            background: '#F2F4F4 !important',
                        },
                        '&:active': {
                            backgroundColor: '#D1D1D1',
                        },
                    }),
                }),
            },
        },

        MuiAlert: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    '& .MuiAlert-message': {
                        color: '#FFF', // Color
                        fontFamily: 'Poppins', // Font family name
                        fontSize: '14px', // Font size
                        fontStyle: 'normal', // Font style
                        fontWeight: 400, // Font weight
                        lineHeight: '20px', // Line height
                    },
                    ...(ownerState.severity === 'warning' && {
                        backgroundColor: '#F29400',
                    }),

                    ...(ownerState.severity === 'error' && {
                        backgroundColor: '#BB3C3C',
                    }),
                }),
            },
        },

        MuiCircularProgress: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.color === 'primary' && {
                        color: '#0a4259',
                    }),

                    ...(ownerState.color === 'success' && {
                        color: '#DDB815',
                    }),
                }),
            },
        },

        MuiLink: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.className?.includes('medium-regular') && {
                        ...mediumStyles,
                    }),
                    ...(ownerState.className?.includes('medium-semibold') && {
                        ...mediumStyles,
                        fontWeight: 600,
                    }),
                    ...(ownerState.className?.includes('medium-bold') && {
                        ...mediumStyles,
                        fontWeight: 700,
                    }),
                }),
            },
        },

        MuiTypography: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...commonStyles,
                    ...(ownerState.className?.includes('h1-regular') && {
                        ...headingStyles,
                    }),
                    ...(ownerState.className?.includes('h1-semibold') && {
                        ...headingStyles,
                        fontWeight: 600,
                    }),
                    ...(ownerState.className?.includes('h1-bold') && {
                        ...headingStyles,
                        fontWeight: 700,
                    }),
                    ...(ownerState.className?.includes('h2-regular') && {
                        fontSize: '16px',
                    }),
                    ...(ownerState.className?.includes('h2-semibold') && {
                        fontSize: '16px',
                        fontWeight: 600,
                    }),
                    ...(ownerState.className?.includes('h2-bold') && {
                        fontSize: '16px',
                        fontWeight: 700,
                    }),
                    ...(ownerState.className?.includes('medium-regular') && {
                        ...mediumStyles,
                    }),
                    ...(ownerState.className?.includes('medium-semibold') && {
                        ...mediumStyles,
                        fontWeight: 600,
                    }),
                    ...(ownerState.className?.includes('medium-bold') && {
                        ...mediumStyles,
                        fontWeight: 700,
                    }),

                    ...(ownerState.className?.includes('small-regular') && {
                        ...mediumStyles,
                    }),
                    ...(ownerState.className?.includes('small-semibold') && {
                        ...smallStyles,
                        fontWeight: 600,
                    }),
                    ...(ownerState.className?.includes('small-bold') && {
                        ...smallStyles,
                        fontWeight: 700,
                    }),

                    ...(ownerState.className?.includes('extra-small-regular') && {
                        ...extraSmallStyles,
                    }),
                    ...(ownerState.className?.includes('extra-small-semibold') && {
                        ...mediumStyles,
                        fontWeight: 600,
                    }),
                    ...(ownerState.className?.includes('extra-small-bold') && {
                        ...extraSmallStyles,
                        fontWeight: 700,
                    }),
                }),
            },
        },

        MuiTextField: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.className?.includes('primary') && {
                        '& .MuiFormLabel-root, & .MuiInputBase-input': {
                            color: '#233142',
                            fontFamily: 'Roboto',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0.5px',
                        },
                        '& .MuiFormHelperText-root': {
                            color: '#BB3C3C', // Color using custom variable
                            fontFamily: 'Poppins', // Font family name
                            fontSize: '10px', // Font size
                            fontStyle: 'normal', // Font style
                            fontWeight: 400, // Font weight
                            lineHeight: 'normal', // Line height
                        },
                    }),
                }),
            },
        },

        MuiFormHelperText: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.className?.includes('error') && {
                        color: 'var(--Red-Warning-100, #BB3C3C)', // Color using custom variable
                        fontFamily: 'Poppins', // Font family name
                        fontSize: '10px', // Font size
                        fontStyle: 'normal', // Font style
                        fontWeight: 400, // Font weight
                        lineHeight: 'normal', // Line height
                    }),
                }),
            },
        },
    },
});
