import { IProvisionUser } from 'pages/login/provision-slice';
import { routes } from '../lib/routes';
import { emptySplitApi } from './empty-split-api';

export const provisionApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getDetailedJWTTokenFromHash: builder.mutation<IProvisionUser, string>({
            query: (hash) => ({
                url: routes.api.auth.getDetailedJWT(hash),
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetDetailedJWTTokenFromHashMutation } = provisionApi;
