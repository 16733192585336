import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../../app';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
    FetchArgs,
    FetchBaseQueryArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { IProvisionUser } from 'pages/login/provision-slice';

export const environment: {
    API_HOST: string;
    DEPLOY_PATH: string;
} = {
    API_HOST: process.env.REACT_APP_API_HOST || '',
    DEPLOY_PATH: process.env.REACT_APP_DEPLOY_PATH || '',
};

export const api = ({
    baseUrl,
    ...rest
}: FetchBaseQueryArgs): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> =>
    fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const authToken = (getState() as RootState)?.auth.credential.jwt;
            const provisionEntity = (getState() as RootState)?.provision.entity as IProvisionUser;
            const validity = provisionEntity.tokenValid;
            let resetAuthToken = undefined;
            if (validity === 'Valid') {
                resetAuthToken = provisionEntity.jwtToken;
            }
            const token = authToken || resetAuthToken;
            // If we have a token set in state, let's assume that we should be passing it
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
        ...rest,
    });

export const defaultApiFetcher = api({});
