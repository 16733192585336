export const TOKEN_FAILURE_MESSAGE = 'Refresh token failed';
export const ALLOWED_ROLES_CAC = ['Assessment Manager', 'Client Manager', 'Manager'];
export const ALLOWED_ROLES_ADC = [
    'goFLUENT CRM',
    'goFLUENT Helpdesk',
    'goFLUENT ADV',
    'goManager',
    'Content Author',
    'goFLUENT Trainer Manager',
];
export const ALLOWED_ROLES_ASSESSMENT = ['Candidate Assessment'];
export const ALLOWED_ROLES_LANGUAGE_ACADEMY = ['Learner', 'FaceToFace Learner'];
export const ALLOWED_ROLES_TRAINER = [
    'VCR Trainer',
    'goFLUENT Trainer',
    'FaceToFace Trainer',
    'eWriting',
    'Trainer Assessment',
    'goFLUENT QA Trainer',
    'goFLUENT Trainer Manager',
];

export const INPUT_PASSWORD_REQUIREMENTS = [
    'Between 8 to 15 characters long',
    'At least 1 letter',
    'At least 1 number',
    'Contains at least 1 of the following characters ! " $ . % & ',
];

export const NO_ACCESS_TO_DESTINATION = 'NO_ACCESS_TO_DESTINATION';
export const CHECKING_DESTINATION = 'CHECKING_DESTINATION';
