export type SimpleRoute = () => string;
export type DynamicRoute = (...args: any[]) => string;

export interface Routing {
    login: {
        index: SimpleRoute;
        forgotPassword: SimpleRoute;
        sso: DynamicRoute;
        resetPassword: DynamicRoute;
        expiredLink: SimpleRoute;
    };
    home: {
        index: SimpleRoute;
    };
    api: {
        auth: {
            credentials: SimpleRoute;
            ssoValidatorSamlWithDomain: DynamicRoute;
            createAuthLegacy: SimpleRoute;
            continue: SimpleRoute;
            resetPassword: SimpleRoute;
            changePassword: SimpleRoute;
            changePasswordProvision: SimpleRoute;
            changePasswordPortalLegacy: SimpleRoute;
            getDetailedJWT: DynamicRoute;
        };
        sso: {
            logout: DynamicRoute;
            availPlatforms: DynamicRoute;
            getSAMLInformation: DynamicRoute;
        };
        localization: {
            langauge: DynamicRoute;
        };
        getUser: DynamicRoute;
    };
}

export const routes: Routing = {
    login: {
        index: () => '/',
        forgotPassword: () => '/forgot-password',
        sso: () => '/samlconnector',
        resetPassword: () => '/password-reset/:tokenHash?',
        expiredLink: () => '/expired-link',
    },
    home: {
        index: () => '/home',
    },
    api: {
        auth: {
            credentials: () => `/api/v1.0/jwt/auth/credentials`,
            ssoValidatorSamlWithDomain: (clientId) => `/api/v1.0.0/sso-validator/saml/${clientId}/client-admin-center`,
            continue: () => '/api/v1.0/jwt/auth/continue',
            createAuthLegacy: () => '/mobile-rest/ws/v1.0/authentication',
            resetPassword: () => `/api/v1/provisioning-service/password-reset/public/ask-password-reset`,
            changePasswordProvision: () => `/api/v1/provisioning-service/password-reset/secure/change-password`,
            changePassword: () => `/api/v1.0/jwt/auth/changepass`,
            changePasswordPortalLegacy: () => `/mobile-rest/ws/newpassword`,
            getDetailedJWT: (hash) => `/api/v1/provisioning-service/password-reset/public/get-jwt-token/${hash}`,
        },
        sso: {
            logout: (clientUrl) => `/api/v1.0.0/sso-validator/saml/platform/client-admin-center/${clientUrl}`,
            availPlatforms: (clientId) => `/api/v1.0.0/sso-validator/saml/platform/${clientId}`,
            getSAMLInformation: ({ clientId, platform, clientDomainUrl, query }) =>
                `/api/v1.0.0/sso-validator/saml/${clientId}/${platform}/${clientDomainUrl}${query ? query : ''}`,
        },
        localization: {
            langauge: (code) => `/api/v1/localization/translation/client-admin-center/1.0.0/${code}`,
        },
        getUser: (userUUID) => `/v1.0/user/${userUUID}`,
    },
};
