import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app';
import { provisionApi } from 'shared/api/provisioning';

type TypeValidityProvision = 'Valid' | 'Expired' | 'Invalid';
type TypePlatforms = 'LanguageAcademy' | 'AssessmentPortal' | 'ClientAdminCenter' | 'TrainerPortal' | 'AdminCenter';
type TokenUsage = 'Default' | 'ActivationLink' | 'PasswordReset';

export interface IProvisionUser {
    tokenHash: string;
    jwtToken: string;
    tokenValid: TypeValidityProvision;
    platformCode: TypePlatforms;
    tokenUsage: TokenUsage;
    tryCount: number;
}

interface IInitialState {
    entity: IProvisionUser;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: IInitialState = {
    entity: {
        tokenHash: '',
        jwtToken: '',
        tokenValid: 'Invalid',
        platformCode: 'LanguageAcademy',
        tokenUsage: 'Default',
        tryCount: 0,
    },
    isLoading: false,
    error: null,
};

export const provisionUserSlice = createSlice({
    name: 'provision',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Activation / Reset Password
        builder.addMatcher(provisionApi.endpoints.getDetailedJWTTokenFromHash.matchPending, (state) => {
            state.isLoading = true;
        });
        builder.addMatcher(provisionApi.endpoints.getDetailedJWTTokenFromHash.matchRejected, (state) => {
            state.isLoading = false;
        });
        builder.addMatcher(provisionApi.endpoints.getDetailedJWTTokenFromHash.matchFulfilled, (state, { payload }) => {
            state.entity = payload;
            state.isLoading = false;
        });
    },
});

const { reducer } = provisionUserSlice;
export default reducer;

export const provisionUserSelector = (state: RootState) => state.provision as IInitialState;
