import { withRouter } from './withRouter';
import { compose } from '@reduxjs/toolkit';
import { withStore } from './withStore';
import { withNotifications } from './withNotifications';
import { withLocalization } from './withLocalization';

export const withHocs = compose(
    withRouter,
    withStore,
    withLocalization,
    withNotifications
);
